<template>
	<div>
		<div :class="['leftmenu',Smenus.length<=0||ltmenuHide?'hidemenu':''] ">
			<div style="width: 160px; margin: 0 auto; height: 100%;">
				<div style="height: 70px;"></div>

				<div class="smenubox" style=" border-radius: 6px;">
					<div :class="['menuitem',nowsMenu==item.id?'menuckd':'']" v-for="(item,idx) in Smenus" :key="idx"
						@click="goPage(item)">
						<i :class="item.icon"></i> {{item.title}}
						<div class="ckdtail">

						</div>
					</div>
				</div>
			</div>

		</div>

		<div v-if="Smenus.length>0" :class="['ltexpand',ltmenuHide?'lt_hide':'']" @click="hideLeftMenu">
			<div :class="['ltarrow',ltmenuHide?'lthide':'ltshow']"></div>
		</div>

		<div class="header">
			<div class="menubar">
				<img :src="logo2" alt="">
			</div>
			<div class="headright">
				<div class="bigmenu flexStart">
					<div :class="['bmitem flexCenter',nowbMenu==item.id?'bmckd':'']" v-for="(item,idx) in Menus"
						:key="idx" @click="goBMenu(item)">
						<i :class="item.icon"></i> {{item.title}}
						<div class="ckline"></div>
					</div>

				</div>
				<div class="nickname">


					<el-dropdown @command="handleCommand" trigger="click">
						<span style="cursor: pointer; color: #333333; border: 0; outline: none;">
							欢迎您:{{userName}} <i class="el-icon-arrow-down"></i>
						</span>
						<el-dropdown-menu slot="dropdown">

							<el-dropdown-item command="updPwd" divided>修改密码</el-dropdown-item>
							<el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>



				</div>
				<div class="headimg">
					<img :src="'./images/temp.jpg'" alt="">
				</div>
			</div>
		</div>


		<el-dialog title="修改密码" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
			width="500px">

			<el-form :model="pwdForm">
				<div style="text-align: center; color: orangered; padding-bottom: 20px;">密码为长度8-16位的字符、数字和特殊字符的组合</div>
				<el-form-item>

					<el-input placeholder="请输入原密码" v-model="pwdForm.oldPwd" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block;width: 60px;">原密码</span></template>
					</el-input>

				</el-form-item>
				<el-form-item>

					<el-input type="password" placeholder="请输入新密码" v-model="pwdForm.newPwd1" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block;width: 60px;">新密码</span></template>
					</el-input>

				</el-form-item>

				<el-form-item>

					<el-input type="password" placeholder="请再次输入新密码" v-model="pwdForm.newPwd2" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block;width: 60px;">确认密码</span></template>
					</el-input>

				</el-form-item>


				<div style="height: 20px; text-align: center; padding-top: 30px; padding-bottom: 20px;">
					<el-button type="primary" style="width: 100%;" @click="savePwd">更改密码</el-button>
				</div>
			</el-form>
		</el-dialog>





	</div>

</template>

<script>
	import aes from '../../utils/aes.js'
	import menus from "./menus.js"
	export default {

		data() {
			return {
				logo2: "",
				year: new Date().getFullYear(),
				yearList: [],
				Menus: [],
				Smenus: [],
				nowbMenu: 0,
				nowsMenu: 0,
				userName: "",
				isShowModal: false,
				isShowYearModal: false,
				pwdForm: {
					oldPwd: "",
					newPwd1: "",
					newPwd2: ''
				},
				ltmenuHide: false
			}
		},
		mounted() {



			this.Menus = menus;

			let userinfo = sessionStorage.getItem("userinfo");
			if (userinfo) {
				this.userName = JSON.parse(userinfo).username
			}

			for (let item of this.Menus) {
				if (item.path && this.$route.path.indexOf(item.path) >= 0) {
					this.nowsMenu = 0
					this.nowbMenu = item.id
				} else {
					if (item.children) {
						for (let sitem of item.children) {
							if (sitem.path && this.$route.path.indexOf(sitem.path) >= 0) {
								this.nowsMenu = sitem.id
								this.nowbMenu = item.id
								this.Smenus = item.children;

								break
							}
						}
					}
				}
			}
			this.getConfig()


		},
		watch: {
			Smenus: function(n, o) {
				this.$parent.leftMenus = n

			},
			ltmenuHide: function(n, o) {
				this.$parent.ltmenuHide = n

			}
		},
		methods: {
			getConfig() {
				this.$http.post("/api/platform_configs").then(res => {
					for (let item of res.data) {
						if (item.ktype == 'left_logo') {
							this.logo2 = item.kvalue;
						}
						if (item.ktype == 'school_name') {
							document.title = "欢迎登录" + item.kvalue 
							
						}
						//首页图标
						if (item.ktype == 'favicon' || item.ktype == 'favico') {
							var link = document.createElement('link');
							link.type = 'image/x-icon';
							link.rel = 'shortcut icon';
							link.href = item.kvalue;
							document.getElementsByTagName('head')[0].appendChild(link);
						}






					}

				})




			},
			goBMenu(item) {
				this.Smenus = item.children;
				this.nowbMenu = item.id
				if (this.Smenus && this.Smenus.length > 0) {
					this.goPage(this.Smenus[0])
					this.nowsMenu = this.Smenus[0].id
				} else if (item.path) {
					if (this.$route.path != item.path) {
						this.$router.push(item.path)
					}


				}
				this.ltmenuHide = false

			},
			goPage(item) {
				if (this.$route.path != item.path) {
					this.$router.push(item.path)
					this.nowsMenu = item.id
				}

			},
			setYear() {

				localStorage.setItem("year", this.year)
				window.location.reload()
			},
			handleCommand(e) {
				console.log(e)
				if (e == 'updPwd') {
					this.isShowModal = true
				} else if (e == 'logout') {
					localStorage.clear();
					sessionStorage.clear();
					this.$router.push("/login")
				} else if (e == 'changeYear') {
					this.isShowYearModal = true
				}
			},
			checkPassWord(value) {
				if (value.length < 8 || value.length > 16) { //最初级别
					this.$message.error("密码长度为8-16位")
					return false;
				}
				if (!/\d/.test(value)) { //如果用户输入的密码 包含了数字
					this.$message.error("密码必须包含数字")
					return false;
				}
				if (!/[a-z]/.test(value) && !/[A-Z]/.test(value)) { //如果用户输入的密码 包含了小写的a到z
					this.$message.error("密码必须包含大小写字母")
					return false;
				}

				var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
					regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;

				if (!regEn.test(value) && !regCn.test(value)) {
					this.$message.error("密码必须包含特殊字符")
					return false;
				}

				return true


			},
			savePwd() {
				if (!this.pwdForm.oldPwd) {
					this.$message.error("请输入原密码")
					return
				}

				if (!this.pwdForm.newPwd1) {
					this.$message.error("请输入新密码")
					return
				}
				if (!this.pwdForm.newPwd2) {
					this.$message.error("请再次输入新密码")
					return
				}
				if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
					this.$message.error("两次密码不一致")
					return
				}
				if (this.checkPassWord(this.pwdForm.newPwd2)) {
					this.$http.post("/api/reset_myself_pwd", this.pwdForm).then(ok => {
						if (ok.data.code) {
							if (ok.data.code == 500) {
								this.$message.error(ok.data.msg)
							} else {
								this.$message.error("保存失败")
							}
						} else {
							this.$message.success("修改成功,即将跳转重新登录！")
							this.pwdForm.newPwd1 = ""
							this.pwdForm.newPwd2 = ""
							this.pwdForm.oldPwd = ""
							setTimeout(() => {

								this.isShowModal = false
								this.$router.push("/login")
							}, 3000)

						}
					})
				}

			},
			hideLeftMenu() {
				this.ltmenuHide = !this.ltmenuHide

			}
		}
	}
</script>
<style scoped>
	.leftmenu {
		width: 200px;
		height: 100%;
		position: absolute;

		transition: all .3s;
		transform-origin: left;
		/* box-shadow: 2px 0 6px rgb(0 21 41 / 35%); */


	}

	.smenubox {
		width: 160px;
		padding-top: 30px;
		margin: 0 auto;
		background-color: #fff;
		min-height: 85%;
		box-shadow: 2px 2px 6px 2px rgba(24, 144, 255, 0.1);
	}

	.hidemenu {
		transform: scale(0, 1);
	}

	.menuitem {
		padding: 10px;
		text-align: left;
		cursor: pointer;
		margin: 0 auto;

		color: #333333;

	}

	.menuckd {

		color: #1890ff;
		box-shadow: 0 0 6px 2px rgba(24, 144, 255, 0.2) inset;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		transform: translateX(10px);
		position: relative;
		background-color: #fafbfc;
	}

	.menuckd .ckdtail {
		width: 40px;
		height: 80px;
		position: absolute;
		top: -20px;
		right: -5px;
		background-image: url(../../../public/images/tail.png);
		background-size: auto 100%;

		background-repeat: no-repeat;
		background-color: #fafbfc;



	}


	.header {
		height: 50px;
		width: 100%;

		position: absolute;

		top: 0;
		z-index: 1;
		/*background-image: url(./images/bg.jpg);*/
		/* background-color: #004EA2; */
		box-shadow: 0 0 6px 2px rgba(24, 144, 255, 0.2);
	}

	.menubar {
		width: 200px;
		height: 100%;
		float: left;
		color: #FFF;
		text-align: center;
		line-height: 50px;
		/* background-color: rgba(24,144,255,0.1); */
	}

	.menubar img {
		width: 100%;
		margin-top: 0px;
	}

	.headright {
		height: 100%;
		margin-left: 200px;
		/*background: linear-gradient(to right, #2d313d, #272b37);*/
		position: relative;
	}

	.headimg {
		width: 30px;
		height: 30px;
		position: absolute;
		top: 10px;
		right: 20px;
		border: 1px solid #000000;
		border-radius: 50%;
	}

	.headimg img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		cursor: pointer;
	}

	.nickname {
		position: absolute;
		right: 60px;
		top: 10px;
		height: 30px;
		line-height: 30px;
		color: #fff;
	}


	.bigmenu {
		position: absolute;
		left: 0;
		height: 100%;
		padding: 0 16px;
	}

	.bmitem {
		box-sizing: border-box;
		padding: 0 20px;
		height: 100%;
		line-height: 30px;
		color: #333333;
		font-weight: bold;
		/*border-bottom: 1px solid #20242d;*/
		cursor: pointer;
		position: relative;
	}

	.bmitem .ckline {
		width: 65%;
		height: 4px;
		background-color: #fff;
		position: absolute;
		bottom: 0;
	}

	.bmckd {
		/*background-image: url(./images/bmbg.jpg);*/
		color: #1890ff;
	}

	.bmckd .ckline {
		background-color: #1890ff;
	}

	.ltexpand {
		width: 15px;
		height: 30px;
		background-color: #d6d6d6;
		position: absolute;
		left: 185px;
		top: 45%;
		border-radius: 4px;
		cursor: pointer;
		transition: all 0.3s;
		z-index: 999;
	}

	.lt_hide {
		left: 0px;
	}

	.ltarrow {
		width: 8px;
		height: 8px;
		border-left: 1px solid #171717;
		border-top: 1px solid #171717;
		transform: rotateZ(135deg);
		margin-left: 5px;
		margin-top: 10px;
		transition: all 0.3s;
	}

	.lthide {
		transform: rotateZ(135deg);
		margin-left: 0;
	}

	.ltshow {
		transform: rotateZ(-45deg);
	}
</style>

<template>
	<div class="page" style="position: relative; overflow: hidden; background-color: #fafbfc;">
		<sysmenu></sysmenu>
		<div :class="['rightcontent',leftMenus.length>0&&!ltmenuHide?'':'expand'] ">
			<div class="rightpage">
				<router-view />
			</div>
		</div>
		<div v-if="downProcess>0" style="height: 2px; background-color: #ccc; position: absolute; width: 100%; top:0">
			<div class="inprocess" :style="{width: downProcess+'%'}"></div>

		</div>
	</div>
</template>

<script>
	import sysmenu from './menu/menu.vue'
	import jwt from 'jsonwebtoken'
	import aes from '../utils/aes.js'
	export default {
		components: {
			sysmenu

		},
		data() {
			return {
				leftMenus: [],
				ltmenuHide: false,
				images: ["https://zhaosheng-saas.oss-cn-hangzhou.aliyuncs.com/1625308477900627.jpg"],
				downProcess: 0
			}
		},
		mounted() {

			
			this.checkToken()


		},
		methods: {

			checkToken() {
				let user = JSON.parse(sessionStorage.getItem("userinfo"));
				
				if(!user){
					this.$router.push("/login")
				}else{
					this.$store.dispatch("setPmid", user.pmid);
				}
				
			}
		}
	}
</script>
<style scoped>
	.rightcontent {
		margin-left: 200px;
		border-top: 50px solid #fff;
		height: 100%;
		box-sizing: border-box;

		transition: margin .3s;
	}

	.expand {
		margin-left: 0px;
	}

	.rightpage {
		/*border-top-left-radius: 6px;*/
		/*padding: 10px;*/

		height: 100%;
		overflow: auto;
		box-sizing: border-box;
	}

	.inprocess {
		height: 100%;
		background-color: orangered;
		width: 0%;

	}
</style>

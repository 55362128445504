export default [{
	id: 4,
	title: "数据统计",
	icon:"el-icon-setting",
	path:"/home",
	children: []
	
},{
	id: 3,
	title: "考试管理",
	icon:"el-icon-setting",
	children: [{
		id: 31,
		title: "题库管理",
		path:"/tiku_tm",
		icon:"el-icon-s-tools"
	},{
		id: 32,
		title: "模拟考试",
		path:"/exam_moni",
		icon:"el-icon-s-tools"
	},{
		id: 33,
		title: "正式考试",
		path:"/exams",
		icon:"el-icon-s-tools"
	}]
},{
	id: 2,
	title: "人员管理",
	icon:"el-icon-setting",
	children: [{
		id: 21,
		title: "教师管理",
		path:"/teacher",
		icon:"el-icon-s-tools"
	},{
		id: 22,
		title: "学生管理",
		path:"/students",
		icon:"el-icon-s-tools"
	},{
		id: 23,
		title: "课程管理",
		path:"/course",
		icon:"el-icon-s-tools"
	}]
},{
	id: 1,
	title: "系统设置",
	icon:"el-icon-setting",
	children: [{
		id: 11,
		title: "系统日志",
		path:"/syslog",
		icon:"el-icon-s-tools"
	},{
		id: 12,
		title: "系统配置",
		path:"/setting",
		icon:"el-icon-s-tools"
	}]
},]
